var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"height":"100%"}},[(_vm.type == 2 && _vm.download)?_c('div',{staticClass:"er-wei-ma"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.erweimaShow),expression:"!erweimaShow"}],staticClass:"erweimaShow"}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.erweimaShow),expression:"erweimaShow"}],ref:"qrCodeUrl",staticClass:"qrcode"}),_vm._m(0)]):_vm._e(),(_vm.carousel)?_c('a-carousel',{attrs:{"arrows":""}},[_c('div',{staticClass:"custom-slick-arrow",staticStyle:{"left":"0.8rem","zindex":"1","transform":"scale(2.5)","z-index":"100"},attrs:{"slot":"prevArrow"},slot:"prevArrow"},[_c('a-icon',{attrs:{"type":"left"}})],1),_c('div',{staticClass:"custom-slick-arrow",staticStyle:{"right":"0.8rem","transform":"scale(2.5)"},attrs:{"slot":"nextArrow"},slot:"nextArrow"},[_c('a-icon',{attrs:{"type":"right"}})],1),_vm._l((_vm.result),function(item,index){return _c('div',{key:'content' + item.moduleId + index,staticClass:"content-wall"},[_c('div',{staticClass:"type-title"},[_c('span'),_vm._v(_vm._s(item[0].name)+" ")]),_c('div',{staticClass:"content"},[_c('div',{staticClass:"box"},[_c('ul',_vm._l((item),function(el,i){return _c('li',{key:i,class:_vm.moduleId == null
                                    ? ''
                                    : _vm.moduleId == el.moduleId
                                    ? 'fx-global-active-' + i + ' fx-global-active'
                                    : 'fx-global',staticStyle:{"position":"relative"},style:(el.moduleId ? 'background-color:' + _vm.bgcJson[i] : ''),on:{"click":function($event){el.lock
                                    ? _vm.clickFoeLock(el)
                                    : el.childrenModules == null
                                    ? _vm.linkToQuestions(el.moduleId)
                                    : _vm.freshList(el.childrenModules, el.moduleName)}}},[(el.lock)?_c('div',{staticStyle:{"background-color":"rgba(0, 0, 0, 0.6)","width":"100%","height":"100%","position":"absolute"}},[_c('div',{staticStyle:{"position":"absolute","top":"0rem","right":"0rem","opacity":"0.6"}},[_c('img',{staticStyle:{"width":"0.8rem","height":"rem"},attrs:{"src":require("../../../../static/images/locking-w.png"),"alt":""}})])]):_vm._e(),(el.moduleId)?_c('div',{staticClass:"main-font"},[_c('div',{staticClass:"main-iconfont"},[_c('img',{attrs:{"src":require('../../../assets/img/reasonList/' +
                                                el.moduleId +
                                                '.png'),"alt":""}})]),_c('span',[_vm._v(_vm._s(el.moduleName))])]):_vm._e()])}),0)])])])})],2):_vm._e()],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"er-wei-ma-font"},[_c('span',[_vm._v("扫描下载文书模板")])])
}]

export { render, staticRenderFns }